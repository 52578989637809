import PageHeader from "../AppComponents/SmallComponents/PageHeader";
import { useEffect } from 'react';

const Error404 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])  
    return ( 
        <div className="error-404">
            <PageHeader title="Error 404"/>
            <p>The page you are looking for does not exist or access is forbidden. Please make sure your link is correct.</p>
        </div>
     );
}
 
export default Error404;