import {useState, useEffect} from 'react';

const useFetch = (url, dependencies = []) => { 
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => { 
        const abortCont = new AbortController();
        fetch(url, { signal: abortCont.signal }).then(res => {
            //console.log(res);
            if(!res) {
                throw Error('Could not fetch the data for that resource');
            }
            return res.json();
        }).then(data => {
            //console.log(data);
            setData(data);
            setIsPending(false);
            setError(null);
        }).catch(err => {
            if (err.name === 'AbortError') {
                //console.log('fetch aborted');
            } else {
                setError(err.message);
                setIsPending(false);
            }
        }) // catches network error
        return () => abortCont.abort();
    }, [url, ...dependencies]);
    return {data, isPending, error, setData}
}

export default useFetch;