import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ContactGallery = () => {
    const image1 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post5/IMG_2563.png";
    return ( 
        <div className="grid-gallery-contact">
            <div className="grid-gallery__item grid-gallery-contact__item--1" style={{backgroundImage: `url(${image1})`}}>
                <div className="absolute grid-gradient1"></div>
                <div className="flex flex-col justify-end h-full p-10">
                    <h4 className="text-white text-3xl font-base bottom-0 z-10 mb-2">Flexible Booking and Locations</h4>
                    <p className="gray-text2 z-10">Available work-around booking schedule and locations with travelling</p>
                </div>
            </div>
            <Link to="/book" className="grid-gallery__item grid-gallery-contact__item--2">
                <div className="flex flex-col justify-end h-full p-10">
                    <h4 className="text-white text-3xl font-base bottom-0 z-10 mb-2">Book Now</h4>
                    <p className="gray-text4 z-10">View available time slots and location preferences</p>
                    <p className="learn-more-contact gray-text4 z-10 mt-5 underline">Learn More &gt;</p>
                </div>
            </Link>
            <Link to="/contact" className="grid-gallery__item grid-gallery-contact__item--3">
                <div className="flex flex-col justify-end h-full p-10">
                    <h4 className="text-white text-3xl font-base bottom-0 z-10 mb-2">Contact</h4>
                    <p className="gray-text4 z-10">Social media, location, phone number, and email</p>
                    <p className="learn-more-contact gray-text4 z-10 mt-5 underline">Learn More &gt;</p>
                </div>
            </Link>
        </div>
     );
}
 
export default ContactGallery;