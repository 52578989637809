const Comments = ({data}) => {
    const comments = data;
    
    return ( 
        <div className="comment-section mt-10">
            {comments.toReversed().map((comment, i) => (
                <div key={i} className="comment mt-8 flex justify-between">
                    <div className="">
                        <p className="text-xl mb-2">{comment.author}</p>
                        <p className="gray-text mb-3">{comment.body}</p>
                    </div>
                    <p className="gray-text">{comment.createdAt.substring(0, 10)}</p>
                </div>
            ))}
        </div>
     );
}
 
export default Comments;