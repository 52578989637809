const PhoneLink = () => {
    return ( 
        <div className="gray-text mt-4">
            <a href="mailto:jaylolfadez@gmail.com" className="flex flex-row items-center"  target="_blank" rel="noreferrer">
                <i className="fa-2x fa-solid fa-phone"></i>
                <p className="ml-3">+1 &#40;437&#41;-226-2233</p>
            </a>
        </div>
     );
}
 
export default PhoneLink;