import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './AppComponents/Navbar';
import Footer from './AppComponents/Footer';
import Home from './Pages/Home';
import Gallery from './Pages/Gallery';
import Intro from './AppComponents/Intro';
import Store from './Store';
import Services from './Pages/Services';
import Book from './Pages/Book';
import PostDetails from './Pages/PostDetails';
import Error404 from './Pages/404';
import Contact from './Pages/Contact';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  return (
    <Store>
      <Router>
        <div className="App">
          <Navbar/>
            <Switch>
              <Route exact path="/">
                <Intro/>
                <div className="main-content">
                    <Home/>
                </div>
              </Route>
              <Route exact path="/gallery">
                <div className="main-content">
                  <Gallery/>
                </div>
              </Route>
              <Route path="/gallery/:id">
                <div className="main-content">
                  <PostDetails/>
                </div>
              </Route>
              <Route path="/services">
                <div className="main-content">
                  <Services/>
                </div>
              </Route>
              <Route path="/book">
                <div className="main-content">
                  <Book/>
                </div>
              </Route>
              <Route path="/contact">
                <div className="main-content">
                  <Contact/>
                </div>
              </Route>
              <Route path="/privacy-policy">
                <div className="main-content">
                  <PrivacyPolicy/>
                </div>
              </Route>
              <Route path="*">
                <div className="main-content">
                  <Error404/>
                </div>
              </Route>
            </Switch>
            <Footer/>
        </div>
      </Router>
    </Store>
  );
}

export default App;
