import ContactGallery from '../AppComponents/SmallComponents/ContactGallery';
import ImageGallery from '../AppComponents/SmallComponents/Gallery';
import HomeServiceCard from '../AppComponents/SmallComponents/HomeServiceCard';
import InstagramPost from '../AppComponents/SmallComponents/InstagramPost';
import SectionHeader from '../AppComponents/SmallComponents/SectionHeader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';

const Home = () => {    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])  
    return ( 
        <div className="Home">
            {/* <SectionHeader title="Pinned"/>
            <InstagramPost/> */}

            <SectionHeader title="My Gallery"/>
            <ImageGallery/>
            <Link to="/gallery" className="redirect-button w-max mt-6 block gray-text underline">View More &gt;</Link>

            <SectionHeader title="Services for All"/>
            <HomeServiceCard/>

            <SectionHeader title="Bookings"/>
            <ContactGallery/>
        </div>
     );
}
 
export default Home;