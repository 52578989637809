import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const HomeServiceCard = () => {
    return ( 
        <div>
            <div className="grid-services">
                <Link to="/services" className="grid-services__item grid-services__item-1" style={{backgroundImage : `url(${"https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post2/img1.png"})`}}>
                    <div className="absolute grid-gradient1"></div>
                    <div className="flex flex-col justify-end h-full p-10">
                        <h4 className="text-white text-3xl font-base bottom-0 z-10 mb-2">Men's Style</h4>
                        <p className="gray-text4 z-10">Hair Cuts, Beard Trims, Eyebrows, line ups, and more</p>
                        <p className="learn-more-services gray-text4 z-10 mt-5 underline">Learn More &gt;</p>
                    </div>
                </Link>

                <Link to="/services" className="grid-services__item grid-services__item-2" style={{backgroundImage : `url(${"https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post8/IMG_2637.png"})`}}>
                    <div className="absolute grid-gradient1"></div>
                    <div className="flex flex-col justify-end h-full p-10">
                        <h4 className="text-white text-3xl font-base bottom-0 z-10 mb-2">Women's Style</h4>
                        <p className="gray-text4 z-10">Hair Cuts, styling, and more</p>
                        <p className="learn-more-services gray-text4 z-10 mt-5 underline">Learn More &gt;</p>
                    </div>
                </Link>
            </div>
        </div>
     );
}
 
export default HomeServiceCard;