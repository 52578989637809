import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../assets/logo-nobg.png';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState, useContext } from 'react';
import { Context } from '../Store';
import { useHistory } from 'react-router-dom';
import Cookies from "universal-cookie";
import CookieConsent from "react-cookie-consent";


const Navbar = () => {
    const cookies = new Cookies();
    
    // google auth
    const { userData } = useContext(Context);
    const [user, setUser] = userData;

    const [cookiesAllowed, setCookiedAllowed] = useState(true);
    const [CLIENT_ID, setCLIENT_ID] = useState("");

    const [open, setOpen] = useState(false);

    const history = useHistory();

    // log in user to google
    function handleCallbackResponse(response) {
        var userObject = jwtDecode(response.credential); // json web token for authorization to google account
        setUser(userObject);
        //console.log(userObject);
        document.getElementById("signInDiv").hidden = true;
        document.getElementById("signInDivMobile").hidden = true;
        
        if (cookiesAllowed){
            cookies.set("jwt_authorization", response.credential, {
                expires: new Date(userObject.exp * 1000)
            })
        }
        
        history.push('/');
    }

    // log out of google
    function handleSignOut(event) {
        setUser({});
        document.getElementById("signInDiv").hidden = false;
        document.getElementById("signInDivMobile").hidden = false;
        
        try {
            cookies.remove('jwt_authorization');
        } catch {

        }

        history.push('/');
    }

    function handleClick () {
        if (open) {
            document.getElementById("nav-links-hamburger").classList.remove('show');
        } else {
            document.getElementById("nav-links-hamburger").classList.add('show');
        }
        setOpen(!open);
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/google-sign-in")
        .then(result => result.json())
        .then(result => setCLIENT_ID(result.clientID))
        .catch(err => console.log(err));
    }, [])

    useEffect(() => {
        /* global google */
        if (CLIENT_ID.length > 0) {
            try {
                const google = window.google;
                google.accounts.id.initialize({
                    client_id: CLIENT_ID,
                    callback: handleCallbackResponse
                })

                google.accounts.id.renderButton(
                    document.getElementById("signInDiv"),
                    { type: "icon", theme: "filled_blue", size: "large", shape: "pill", text: "signin"}
                );

                google.accounts.id.renderButton(
                    document.getElementById("signInDivMobile"),
                    {theme: "filled_blue", size: "large", shape: "pill", text: "signin"}
                );
            } catch {
                console.log("Cannot Connect to google services")
            }

            try {
                const jwtToken = cookies.get('jwt_authorization');
                var userObject = jwtDecode(jwtToken);
                setUser(userObject);
                document.getElementById("signInDiv").hidden = true;
            } catch {
                //console.log("error");
            }
        }
    }, [open, CLIENT_ID]);


    return ( 
        <>
            <div className="navbar w-full h-20 sticky top-0 z-50" id="navbar">
                <div className="navbar-container h-full flex justify-between items-center">
                    <Link to="/" className="flex items-center">
                        <img src={logo} alt="logo" className="w-16 h-16"/>
                        <h2 className="font-bold text-white text-2xl ml-2">Jaylolfadez</h2>
                    </Link>
                    {/* hamburger menu */}
                    <div className="hamburger-menu">
                        <button onClick={handleClick}>
                            <i className="fa-solid fa-bars gray-text text-3xl hover-link"></i>
                        </button>
                    </div>

                    {/* Fully open */}
                    <ul className="nav-links nav-links-full flex items-center">
                        <li><Link to="/" className="text-base gray-text">Home</Link></li> 
                        <li><Link to="/gallery" className="text-base gray-text">Gallery</Link></li> 
                        <li><Link to="/services" className="text-base gray-text">Services</Link></li> 
                        <li><Link to="/book" className="text-base gray-text">Book</Link></li> 
                        <li><Link to="/contact" className="text-base gray-text">Contact</Link></li> 
                        <li className="google-accounts">
                            <div id="signInDiv" className=""></div>
                            { Object.keys(user).length !== 0 &&
                                <button onClick={(e) => handleSignOut(e)} className="signout-button rounded-full color1-text flex items-center">
                                    {user && <img src={user.picture} className="rounded-full h-8 mr-2" alt="profile"/>}
                                    Sign Out
                                </button>
                            }
                        </li> 
                    </ul>   
                </div>

                <ul className="nav-links-hamburger gap-5" id="nav-links-hamburger">
                    <li><Link to="/" className="text-xl gray-text hover-link">Home</Link></li> 
                    <li><Link to="/gallery" className="text-xl gray-text hover-link">Gallery</Link></li> 
                    <li><Link to="/services" className="text-xl gray-text hover-link">Services</Link></li> 
                    <li><Link to="/book" className="text-xl gray-text hover-link">Book</Link></li> 
                    <li><Link to="/contact" className="text-xl gray-text hover-link">Contact</Link></li> 
                    <li className="google-accounts-mobile">
                        <div id="signInDivMobile" className=""></div>
                        { Object.keys(user).length !== 0 &&
                            <button onClick={(e) => handleSignOut(e)} className="signout-button rounded-full color1-text flex items-center">
                                {user && <img src={user.picture} className="rounded-full h-8 mr-2" alt="profile"/>}
                                Sign Out
                            </button>

                        }
                    </li> 
                </ul>

                
            </div>
            <CookieConsent
            buttonStyle={{backgroundColor : "#FFFFfF", fontFamily: "rubik"}}
            style={{background: '#000000'}}
            enableDeclineButton
            declineButtonStyle={{background: '#ff2e2e'}}
            onAccept={(acceptedByScrolling) => {
                setCookiedAllowed(true);
            }}

            onDecline={() => {
                setCookiedAllowed(false);
            }}
            >This website uses cookies to enhance the user experience.</CookieConsent>
        </>
        
     );
}
 
export default Navbar;