import EmailLink from "../AppComponents/SmallComponents/Email";
import Instagram from "../AppComponents/SmallComponents/Instagram";
import PageHeader from "../AppComponents/SmallComponents/PageHeader";
import PhoneLink from "../AppComponents/SmallComponents/Phone";
import Tiktok from "../AppComponents/SmallComponents/Tiktok";
import { useEffect, useState } from 'react'
import jaycontactpicture from "../assets/jaycontactpicture.jpg";


const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])  

    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [message, setMessage] = useState('');

    // const [isPending, setIsPending] = useState(false);
    // const [sent, setSent] = useState(false); 

    // const handleSubmit = (e) => {
    //     setIsPending(true);
    //     e.preventDefault();
    //     const form = document.forms["submit-to-google-sheet"];
    //     const body = new FormData(form);
    //     console.log(body);

    //     fetch(process.env.REACT_APP_API_URL + '/contact-post', {
    //         method: 'POST',
    //         body: body
    //     }).then(result => {
    //         setIsPending(false);
    //         setSent(true);
    //         setName('');
    //         setEmail('');
    //         setMessage('');
    //     }).catch(err => console.log(err));
    // }

    return ( 
        <div className="contact">
            <PageHeader title="Contact"/>
            <div className="mt-5">
                <div className="contact-info">
                    <div className="contact-image" style={{backgroundImage : `url(${jaycontactpicture})`}}>
                    </div>
                    <div className="">
                        <h2 className="text-3xl font-bold">Let's Get In Touch!</h2>
                        <p className="gray-text mt-4">Thank you for checking out my website! 
                            For any further questions, inquiries, and special booking requests, feel free to reach out to me through email, phone number or social media.</p>
                        
                        <div>
                            <p className="gray-text mt-10">University Address: UWP Wellesley South (WES) Room 316</p>
                            <EmailLink/>
                            <PhoneLink/>
                            <div className="social-container flex justify-start mt-4">
                                <Instagram/>
                                <Tiktok/>
                            </div>    
                        </div>
                    </div>
                                
                </div>
                {/* <form className="contact-form flex flex-col justify-evenly add-shadow" onSubmit={handleSubmit} name="submit-to-google-sheet">
                    <input type="text" placeholder="Full Name" name="Name" required value={name} onChange={(e) => setName(e.target.value)}></input>
                    <input type="email" placeholder="Email" required name="Email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    <textarea placeholder="Your Message" required rows="10" name="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    <div className="flex items-center flex-wrap gap-10">
                        {isPending && <button className="main-buttons" disabled>Sending</button>}
                        {!isPending && <button className="main-buttons ">Contact</button>}
                        {sent && <p className="gray-text">Sent Successfully</p>}
                    </div>
                    
                    
                </form> */}
            </div>
        </div>
     );
}
 
export default Contact;