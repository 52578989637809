import PageHeader from "../AppComponents/SmallComponents/PageHeader";
import useFetch from '../useFetch';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';

const Gallery = () => {
    const { data, isPending, error } = useFetch(process.env.REACT_APP_API_URL + '/gallery', []);
    const [page, setPage] = useState(0);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [maxPage, setMaxPage] = useState(0);
    
    const imgPerPage = 12; 
    const minPage = 0;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])  

    useEffect(() => {
        if (data) {
            setMaxPage(Math.floor(data.length / imgPerPage));
            setCurrentPageData(data.slice(page * imgPerPage, (page+1) * imgPerPage));
        }
    }, [data, page]);
    // use online image link hosting

    const temparr = new Array(imgPerPage/2).fill(0);

    return ( 
        <div className="gallery">
            <PageHeader title="Gallery"/>
            <div className="gallery-post-container flex flex-wrap gap-10">
                {isPending && (
                    temparr.map((i, idx) => (
                        <div className="p-4" key={idx}>
                            <Skeleton variant="rounded" width={350} height={400} />
                            <Skeleton variant="rounded mt-2" width={350} height={30} />
                        </div>
                    ))

                )}
                {data && data.length === 0 && <p>No images found</p>}
                {data && data.length > 0 && 
                    currentPageData.map((post, i) => (
                        <Link to={`/gallery/${post._id}`} key={i} className="preview-container p-4">
                            <div className="gallery-image-cont">
                                <div style={{backgroundImage: `url(${post.previewImage})`}}
                                className="gallery-image-preview"></div>
                            </div>
                            <div className="flex flex-wrap items-center gap-6 gray-text mt-5">
                                <div className="flex items-center">
                                    <i className="fa-regular fa-heart mr-2"></i>
                                    <p>{post.likes.length}</p>
                                </div>
                                <div className="flex items-center">
                                    <i className="fa-regular fa-comment mr-2"></i>
                                    <p>{post.comments.length}</p>
                                </div>
                                <p className="ml-auto">{post.createdAt.substring(0, 10)}</p>
                            </div>
                            
                        </Link>
                    ))
                }
                {error && <p className="gray-text">There was an error fetching data</p>}
                
            </div>
            <div className="flex justify-center gap-5 mt-10">
                <button className="gray-text" onClick={(e) => {setPage(page => (page > minPage) ? page - 1 : minPage)}}>Previous</button>
                <p>{page + 1} / {maxPage + 1}</p>
                <button className="gray-text" onClick={(e) => {setPage(page => (page < maxPage) ? page + 1 : maxPage)}}>Next</button>
            </div>
        </div>
     );
}
 
export default Gallery;