import { InstagramEmbed } from 'react-social-media-embed';

const InstagramPost = () => {
    return ( 
        <div className="flex justify-center gap-10 flex-wrap">
            <InstagramEmbed url="https://www.instagram.com/p/C5PbTEYAOrC/?img_index=1" className="ig-post add-shadow" />
            <InstagramEmbed url="https://www.instagram.com/reel/C70r59PgALA/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" className="ig-post add-shadow" />
            <InstagramEmbed url="https://www.instagram.com/reel/C8LjDPGAocT/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" className="ig-post add-shadow" />
        </div>
     );
}
 
export default InstagramPost;