import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import EmailLink from './SmallComponents/Email';
import PhoneLink from './SmallComponents/Phone';
import Instagram from './SmallComponents/Instagram';
import Tiktok from './SmallComponents/Tiktok';

const Footer = () => {
    return ( 
        <div className="footer bg-black p-5 mt-40">
            <div className="footer-container flex justify-evenly items-start">
                <div className="side-contact flex flex-col">
                    <h4 className="text-white text-2xl font-bold">Jaylolfadez</h4>
                    <EmailLink/>
                    <PhoneLink/>
                    <div className="social-container flex justify-start mt-8">
                        <Instagram/>
                        <Tiktok/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <h4 className="text-white text-2xl font-bold mb-2">Webpage</h4>
                    <ul className="footer-links flex flex-col">
                        <li><Link to="/" className="text-base">Home</Link></li> 
                        <li><Link to="/gallery" className="text-base">Gallery</Link></li> 
                        <li><Link to="/services" className="text-base">Services</Link></li> 
                        <li><Link to="/book" className="text-base">Book</Link></li> 
                        <li><Link to="/contact" className="text-base">Contact</Link></li> 
                        <li><Link to="/privacy-policy" className="text-base">Privacy Policy</Link></li> 
                    </ul>
                </div>
            </div>
            <div className="separator1 w-full h-0.5"></div>
            {/* <div className="flex justify-center">
                <p className="text-white mt-10 font-light gray-text">Copyright &copy; Lucian Cheng - Jaylolfadez</p>
            </div> */}
        </div>
     );
}
 
export default Footer;