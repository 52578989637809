import React, { useState } from 'react';

const initialState = {}

export const Context = React.createContext();

const Store = ({ children }) => {
    const [user, setUser] = useState(initialState);
    const [tokenClient, setTokenClient] = useState(initialState);
    return (
        <Context.Provider value={{userData: [user, setUser], 
            token: [tokenClient, setTokenClient]}}>
                
                {children}
            
            </Context.Provider>
    )
};

export default Store;