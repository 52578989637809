import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useRef } from 'react';

const Intro = () => {
    const videoRef = useRef(null);
    const video = "https://jaylolfadez.s3.us-east-2.amazonaws.com/video/Background+Vid1.mp4"

    return ( 
        <div className="intro relative bg-black">
            {/* Respnsive for computer */}
            <div className="w-full brightness-50 overflow-hidden flex items-center video-container">
                <video ref={videoRef} className="blur-sm" autoPlay loop muted playsInline >
                    <source src={video} type="video/mp4"/>
                    video could not be played
                </video>
            </div>
            {/* Respnsive for mobile */}
            <div>

            </div>
            <div className="intro-container absolute text-white">
                <h1 className="font-bold text-6xl">Jaylolfadez</h1>
                <h3 className="mt-5 gray-text">Premium Haircuts</h3>
                <div>
                    <Link to="/book" className="booknow-button main-buttons mt-12 flex items-center justify-center">
                        Book Now    
                        <i className="fa-solid fa-chevron-right ml-2"></i>
                    </Link>
                </div>
            </div>
        </div>
     );
}
 
export default Intro;