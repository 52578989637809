const EmailLink = () => {
    return ( 
        <div className="gray-text mt-4">
            <a href="mailto:jaylolfadez@gmail.com" className="flex flex-row items-center"  target="_blank" rel="noreferrer">
                <i className="fa-2x fa-solid fa-envelope"></i>
                <p className="ml-3">jaylolfadez@gmail.com</p>
            </a>
        </div>
     );
}
 
export default EmailLink;