import { useParams } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PageHeader from "../AppComponents/SmallComponents/PageHeader";
import { useEffect, useState, useContext } from 'react';
import useFetch from '../useFetch';
import { Context } from '../Store';
import Comments from "../AppComponents/SmallComponents/comments";
import { Skeleton } from '@mui/material';

import {
	RegExpMatcher,
	TextCensor,
	englishDataset,
	englishRecommendedTransformers,
} from 'obscenity';

const PostDetails = () => {
    const { id } = useParams();
    const { data, isPending, error, setData} = useFetch(process.env.REACT_APP_API_URL + '/gallery/' + id, []);
    const [currentImage, setCurrentImage] = useState(0);

    // global user data
    const { userData } = useContext(Context);
    const [user] = userData;

    // form data
    const [body, setBody] = useState('');

    // like button
    const [isLiked, setIsLiked] = useState(false);

    // too many req
    const [tooManyReq, setTooManyReq] = useState(false);

    // profanity filter
    const matcher = new RegExpMatcher({
        ...englishDataset.build(),
        ...englishRecommendedTransformers,
    });

    const censor = new TextCensor();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (data && Object.keys(user).length !== 0) {
            if (data.likes.includes(user.email)) {
                setIsLiked(true);
            } 
        }
    }, [data, user]);

    const handlePost = (e) => {
        if (Object.keys(user).length === 0) return;

        e.preventDefault();  

        const matches = matcher.getAllMatches(body);
        const text = censor.applyTo(body, matches);

        const details = { author : user.name, body : text};

        fetch(process.env.REACT_APP_API_URL + `/gallery/${id}/comment`, 
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'},
            body: JSON.stringify(details),
            mode: 'cors',
            cache: 'default'
        })
        .then(result => {
            if (result.status === 429) {
                return result.json().then(errorData => {
                    setTooManyReq(true);
                    throw new Error(errorData.message);
                })
            }
            if(!result.ok) {
                return result.json().then(errorData => {
                    throw new Error(errorData.message);
                })
            }
            return result.json();
        })
        .then(result => {
            setTooManyReq(false);
            setBody('');
            setData(result);
        }).catch(err => console.log(err.message || 'an error has occured while fetching data'));
    }

    const handleLike = (e) => {
        if (!data) return;
        if (Object.keys(user).length === 0) return;

        const email = user.email;

        let newLikeList = []

        if (data.likes.includes(email)) {
            // remove like
            newLikeList = data.likes.filter(em => em !== email);
            setIsLiked(false);
        } else {
            // add like
            data.likes.push(email);
            newLikeList = data.likes;
            setIsLiked(true);
        }

        const body = {likes : newLikeList};

        fetch(process.env.REACT_APP_API_URL + `/gallery/${id}/like`,
        {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'},
            body: JSON.stringify(body),
            mode: 'cors',
            cache: 'default'
        })
        .then(res => res.json())
        .then(result => {
            setData(result);
        }).catch(err => console.log(err));

    }

    return ( 
        <div>
            <PageHeader title="Details"/>
            <Link to="/gallery" className="gray-text underline">Back to Gallery</Link>
            {error && <p>Error fetching data</p>}
            {isPending && <div>
                <Skeleton variant="rounded" className="w-auto mt-5 details-image-container" />
                <Skeleton variant="rounded" className="w-auto mt-4" height={40} />
                <Skeleton variant="rounded" className="w-auto mt-14" height={40} />
                <Skeleton variant="rounded" className="w-auto mt-5" height={80} />
                </div>}
            {data &&
            <div>
                <div className="details-image-container w-auto mt-5" 
                style={{backgroundImage: `url(${data.allImages[currentImage]})`}}>
                </div>
                <div className="flex flex-wrap items-center gap-6 gray-text mt-4 text-xl">
                    <button className="flex items-center" onClick={handleLike}>
                        {isLiked && <i className="fa-solid fa-heart mr-2 text-red-500"></i>}
                        {!isLiked && <i className="fa-regular fa-heart mr-2"></i>}
                        <p>{data.likes.length}</p>
                    </button>
                    <div className="flex justify-center gap-5 text-base ml-auto">
                        <button className="gray-text" onClick={(e) => setCurrentImage(cur => (cur > 0) ? cur - 1 : 0)}>Previous</button>
                        <p>{currentImage + 1} / {data.allImages.length}</p>
                        <button className="gray-text" onClick={(e) => setCurrentImage(cur => (cur < (data.allImages.length - 1)) ? cur + 1 : data.allImages.length - 1)}>Next</button>
                    </div>
                </div>

                <h3 className="text-2xl mt-14">{data.comments.length} Comments</h3>
                
                <form className="comment-form flex items-center mt-5" onSubmit={handlePost}>
                    <textarea 
                    className="w-full"
                    placeholder="Add a comment (200 characters) ..."
                    required
                    value={body}
                    maxLength="200"
                    onChange={(e) => setBody(e.target.value)}></textarea>
                    {Object.keys(user).length === 0 &&
                        <div className="flex flex-col justify-center items-center">   
                            <label className="gray-text text-sm ml-7 mr-7">Sign In to Post</label>
                        </div>
                    }
                    {Object.keys(user).length !== 0 && 
                        <div className="flex flex-col justify-center items-center">   
                            <button className="ml-7 mr-7 mb-2">Post</button>
                            <label className="gray-text text-xs">As {user.name}</label>
                        </div>
                    }
                </form>
                {tooManyReq && <p className="gray-text mt-2">Too many requests, please try again later</p>}

                <Comments data={data.comments}/>
            </div>
            }
        </div>
     );
}
 
export default PostDetails;